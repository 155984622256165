/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @update 2017/08/12 10:37
 */

import React from 'react';
import {browserHistory} from "react-router";
import Config from "../../config";
import {Animated} from "react-animated-css";
import InlineSVG from "react-inlinesvg";
import {Button, ButtonGroup, Tooltip, withStyles,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    ExpansionPanel,
    Typography
} from "@material-ui/core";
import {Row} from "react-bootstrap";
import Icons from "../common/icons";

const styles = theme => ({
    collapseBoardContent: {
        padding: '0 !important',
        flexDirection: 'column'
    },
    heading: {
        fontSize: 16,
        fontWeight: 450,
        textTransform: "uppercase",
    },
    headingMinium: {
        fontSize: 11,
        fontWeight: 450,
        textTransform: "uppercase",
        textAlign: "center",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 60
    },
    collapseBoard: {
        width: "100%",
        position: 'initial !important',
        boxShadow: 'initial !important',
        margin: '0 0 5px 0 !important'
    },
    ExpansionPanelSummary: {
        minHeight: 'initial !important',
        padding: "8px 0",
        width: "100%",
        color: theme.palette.primary.main,
        "& .MuiExpansionPanelSummary-content": {
            margin: "0 !important",
            display: "block"
        },
        "&.Mui-expanded": {
            minHeight: "inherit"
        }
    },
});
class SideNav extends React.Component{

    heightMenu = 42;

    constructor(props) {
        super(props);
        this.state={
            showMore: false,
            showMoreChild: false,
            expanded: false,
            expandGroup: {},
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.expand !== this.props.expand) {
            this.toggleNav(this.props.expand);
        }
    }

    getNestedChildren = (arr, parent) => {
        let out = [];
        arr.forEach((key)=>{
            if(key.ParentMenuID === parent) {
                let children = this.getNestedChildren(arr, key.MenuID);
                if(children.length) {
                    key.children = children;
                }
                out.push(key);
            }
        });
        return out;
    };

    // getClassNameHome = () => {
    //     const pth = window.location.pathname.split('/');
    //     const mdl = pth && pth[1] ? pth[1] : '';
    //     let cls = 'menu-item-child-home';
    //     if (!mdl) {
    //         cls = 'menu-item-child-home active';
    //     }
    //     return cls;
    // };

    // getClassName = (m, idx, child) => {
    //     const pth = window.location.href.split('/');
    //     const mdl = (pth && pth.length > 0) ? pth[pth.length - 1] : '';
    //
    //     let cls = 'menu-item-child';
    //     if (child) cls += '-child';
    //
    //     if(m.Disabled === true) {
    //         cls += ' disabled-icon';
    //     }
    //
    //     if (m && mdl === m.FormID && m.FormID !== '') {
    //         cls += ' active';
    //     }
    //
    //     return cls;
    // };
    //
    // onMenuClick = (item) => {
    //     const url = item.FormID;
    //     if (!url || url === '' || url === '#' || (item.children && item.children.length>0)) {
    //         if(document.body.offsetWidth <= 720){
    //             this.toggleNav();
    //         }
    //         return false;
    //     }
    //     if (url === '/') {
    //         browserHistory.push(Config.getRootPath() + '/');
    //         let el = document.querySelector('.menu-item-child.active');
    //         let elChild = document.querySelector('.menu-item-child-child.active');
    //         if (el) el.className = 'menu-item-child';
    //         if (elChild) elChild.className = 'menu-item-child-child';
    //         const elHome = document.querySelector('.menu-item-child-home');
    //         if (elHome) elHome.className = 'menu-item-child-home logo active';
    //     } else {
    //         browserHistory.push(Config.getRootPath() + url);
    //         let el = document.querySelector('.menu-item-child.active');
    //         let elChild = document.querySelector('.menu-item-child-child.active');
    //         if (el) el.className = 'menu-item-child';
    //         if (elChild) elChild.className = 'menu-item-child-child';
    //         const elHome = document.querySelector('.menu-item-child-home');
    //         if (elHome) elHome.className = 'menu-item-child-home logo';
    //         if(this.refs[url] && this.refs[url].classList) this.refs[url].classList.add('active');
    //     }
    //
    //     if(document.body.offsetWidth <= 720){
    //         this.toggleNav();
    //     }
    // };

    toggleNav = (flag) => {
        const btnToogle = document.querySelector('#toogle-nav').children[0];
        if (typeof flag === "undefined") {
            if (this.refs['side-container'].clientWidth < 180) {
                // this.checkShowMore(this.dataMenuParse);
                this.refs['side-container'].classList.remove('minimum');
                if (btnToogle) {
                    btnToogle.classList.add('active');
                }
            } else {
                this.refs['side-container'].classList.add('minimum');
                if (btnToogle) {
                    btnToogle.classList.remove('active');
                }
            }
        } else {
            if (flag) {
                if (btnToogle) {
                    btnToogle.classList.add('active');
                }
                this.setState({expanded: true});
                this.refs['side-container'].classList.remove('minimum');
            } else {
                if (btnToogle) {
                    btnToogle.classList.remove('active');
                }
                this.setState({expanded: false});
                this.refs['side-container'].classList.add('minimum');
            }
        }
    };

    onLogout = () => {
        if(this.props.onLogout) this.props.onLogout();
    };

    toggleMenuESS = (number) => {
        const {isTab, toggleMenuEss} = this.props;
        if(isTab === number) return;
        if(toggleMenuEss) toggleMenuEss(number, true);
    };

    // _getResourceImage = (fileName) => {
    //     let icon = require('../../assets/images/menu/default.svg');
    //     try {
    //         icon = require('../../assets/images/menu/' + fileName);
    //     } catch (e) {
    //
    //     }
    //     return icon;
    // };

    render() {
        const {isTab, className, classes} = this.props;
        const {expanded, expandGroup} = this.state;

        let dataMenu = null;
        if (Config.menu) {
            switch (isTab) {
                case 2:
                    dataMenu = [
                        {
                            AppFormID: "",
                            FormCall: "W39F3000",
                            FormDesc: Config.lang("Danh_sach_muc_tieu"),
                            FormID: "W39F3000",
                            FormIDPermission: "W39F3000",
                            IsAW: 0,
                            IsPermission: 0,
                            MenuGroupID: "Goal",
                            MenuID: "MHRP39N0008",
                            Parameters: "",
                            ProductID: "WHRP",
                            Type: "OKR",
                        },
                        {
                            AppFormID: "",
                            FormCall: "W39F3025",
                            FormDesc: Config.lang("Ban_do_muc_tieu"),
                            FormID: "W39F3025",
                            FormIDPermission: "",
                            IsAW: 0,
                            IsPermission: 0,
                            MenuGroupID: "Goal",
                            MenuID: "MHRP39N0009",
                            Parameters: "",
                            ProductID: "WHRP",
                            Type: "OKR",
                        },
                        {
                            AppFormID: "",
                            FormCall: "W39F3010",
                            FormDesc: Config.lang("Review_muc_tieu"),
                            FormID: "W39F3010",
                            FormIDPermission: "W39F3010",
                            IsAW: 0,
                            IsPermission: 1,
                            MenuGroupID: "Goal",
                            MenuID: "MHRP39N0010",
                            Parameters: "",
                            ProductID: "WHRP",
                            Type: "OKR",
                        },
                        {
                            AppFormID: "",
                            FormCall: "W39F3020",
                            FormDesc: Config.lang("Danh_sach_duyet_ket_qua_muc_tieu_ca_nhan"),
                            FormID: "W39F3020",
                            FormIDPermission: "W39F3020",
                            IsAW: 0,
                            IsPermission: 1,
                            MenuGroupID: "Goal",
                            MenuID: "MHRP39N0011",
                            Parameters: "",
                            ProductID: "WHRP",
                            Type: "OKR",
                        },
                        {
                            AppFormID: "",
                            FormCall: "W39F3035",
                            FormDesc: Config.lang("Xep_loai_danh_gia_nhan_vien"),
                            FormID: "W39F3035",
                            FormIDPermission: "W39F3035",
                            IsAW: 0,
                            IsPermission: 0,
                            MenuGroupID: "Goal",
                            MenuID: "MHRP39N0015",
                            Parameters: "",
                            ProductID: "WHRP",
                            Type: "OKR",
                        }
                    ];
                    break;
                case 1:
                    dataMenu = Config.menu.listMSS ? Config.menu.listMSS : null;
                    break;
                case 0:
                default:
                    dataMenu = Config.menu.listESS ? Config.menu.listESS : null;
                    break;
            }
        }
        dataMenu = !dataMenu ? [] : dataMenu;

        return(
            <div className={"side-container minimum " + className} ref={'side-container'}>
                <div className="nav-scroll">
                    <div className={'display_row align-center valign-middle group-menu-ess'}>
                        <ButtonGroup
                            variant="contained"
                            size="medium"
                            aria-label="medium outlined secondary button group"
                        >
                            <Button onClick={() => this.toggleMenuESS(0)}
                                    color={!isTab ? 'primary' : 'inherit'}
                                    className={!isTab ? 'ess-active' : 'ess-inactive'}
                            >
                                ESS
                            </Button>
                            <Button onClick={() => this.toggleMenuESS(1)}
                                    color={isTab ? 'primary' : 'inherit'}
                                    className={isTab === 1 ? 'ess-active' : 'ess-inactive'}
                            >
                                MSS
                            </Button>
                        </ButtonGroup>
                    </div>
                    {isTab !== 2 && dataMenu && dataMenu.map((menu, idx) => {
                        // const cls = this.getClassName(o, idx);
                        if (menu.MenuItem && menu.MenuItem.length <= 0) return null;
                        const _expand = typeof expandGroup[menu.MenuGroupID] === "undefined" ? true : expandGroup[menu.MenuGroupID];
                        const name = !expanded ? menu.ShortName : menu.MenuGroupName;
                        return (
                            <ul className={'nav-item pdl10 pdr10 item-' + menu.MenuGroupID}
                                key={'ul-' + idx}
                            >
                                <div className="display_row align-between align-center">
                                    <ExpansionPanel
                                        key={menu.MenuGroupID}
                                        expanded={_expand}
                                        className={classes.collapseBoard}
                                        onChange={() => this.setState({ expandGroup: {...this.state.expandGroup, [menu.MenuGroupID]: !_expand}})}
                                    >
                                        <ExpansionPanelSummary className={classes.ExpansionPanelSummary}>
                                            <Animated style={{width: '100%'}}
                                                // key={o.FormID + idx}
                                                // ref={o.FormID}
                                                      animationIn="flipInX"
                                                      animationInDuration={1000}
                                                      isVisible={true}>
                                                <Tooltip title={menu.MenuGroupName}
                                                         classes={{tooltip: "no-margin"}}
                                                         disableHoverListener={expanded}
                                                         placement={expanded ? "bottom" : "right"}
                                                         aria-label={menu.MenuGroupName}>
                                                    <div className={"display_row align-center align-between"}>
                                                        <Typography className={classes[!expanded ? "headingMinium" : "heading"]}>{name}</Typography>
                                                        {expanded && <Icons className={"fa mgr5 display_row align-center " + (_expand ? "fa-angle-down " : "fa-angle-left ") + classes.iconArrowCollapse}/>}
                                                    </div>
                                                </Tooltip>
                                            </Animated>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                            <Row className={'dashboard-container--group ' + (dataMenu && dataMenu.length > 4 ? 'valign-top' : 'valign-middle')}>
                                                {menu.MenuItem && menu.MenuItem.map((item, idx)=>{
                                                    return(
                                                        <ItemMenu key={idx} expanded={expanded} index={idx} data={item} toggleNav={this.toggleNav}/>
                                                    )
                                                })}
                                            </Row>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            </ul>
                        )
                    })}
                    {isTab === 2 && dataMenu && dataMenu.map((menu, idx) => {
                        return (
                            <ItemMenu key={idx} expanded={expanded} index={idx} data={menu} toggleNav={this.toggleNav}/>
                        )
                    })}
                </div>
                {/*<div className={'icon-nav'}>*/}
                {/*    <Image src={this.state.iconExpand} onClick={this.toggleNav}/>*/}
                {/*</div>*/}
            </div>
        )
    }
}

class ItemMenu extends React.Component {

    getClassName = (m, idx, child) => {
        const pth = window.location.href.split('/');
        const mdl = (pth && pth.length > 0) ? pth[pth.length - 1] : '';

        let cls = 'menu-item-child';
        if (child) cls += '-child';

        if(m.Disabled === true) {
            cls += ' disabled-icon';
        }

        if (m && mdl === m.MenuID && m.MenuID !== '') {
            cls += ' active';
        }

        return cls;
    };

    _getResourceImage = (fileName) => {
        let icon = require('../../assets/images/menu/default.svg');
        try {
            icon = require('../../assets/images/menu/' + fileName);
        } catch (e) {

        }
        return icon;
    };

    onMenuClick = (item) => {
        const url = item.FormCall;
        const parameter = item.Parameters ? JSON.parse(item.Parameters) : null;
        const params = {
            menu: item,
            ...(parameter ? {...parameter} : {})
        };
        if (!url || url === '' || url === '#' || (item.children && item.children.length>0)) {
            if(document.body.offsetWidth <= 720){
                this.toggleNav();
            }
            return false;
        }
        if (url === '/') {
            browserHistory.push({
                pathname: Config.getRootPath() + '/',
                state: params
            });
            let el = document.querySelector('.menu-item-child.active');
            let elChild = document.querySelector('.menu-item-child-child.active');
            if (el) el.className = 'menu-item-child';
            if (elChild) elChild.className = 'menu-item-child-child';
            const elHome = document.querySelector('.menu-item-child-home');
            if (elHome) elHome.className = 'menu-item-child-home logo active';
        } else {
            browserHistory.push({
                pathname: Config.getRootPath() + url,
                state: params
            });
            let el = document.querySelector('.menu-item-child.active');
            let elChild = document.querySelector('.menu-item-child-child.active');
            if (el) el.className = 'menu-item-child';
            if (elChild) elChild.className = 'menu-item-child-child';
            const elHome = document.querySelector('.menu-item-child-home');
            if (elHome) elHome.className = 'menu-item-child-home logo';
            if(this.refs[url] && this.refs[url].classList) this.refs[url].classList.add('active');
        }

        const {toggleNav} = this.props;
        if(toggleNav && document.body.offsetWidth <= 720){
            toggleNav();
        }
    };

    render(){
        const {data: o, index: idx, expanded} = this.props;
        const cls = this.getClassName(o, idx);
        let height = this.heightMenu;
        return(
            <ul className={'nav-item item-' + o.MenuID}
                style={{height: height, minHeight: height}}
                key={'ul-' + idx}
            >
                <div className="display_row align-between align-center">
                    <Animated style={{width: '100%'}}
                        // key={o.FormID + idx}
                        // ref={o.FormID}
                              animationIn="flipInX"
                              animationInDuration={1000}
                              isVisible={true}>
                        <Tooltip title={o.FormDesc || o.MenuName}
                                 classes={{tooltip: "no-margin"}}
                                 placement={expanded ? "bottom" : "right"}
                                 aria-label={o.FormDesc || o.MenuName}>
                            <div className={cls}
                                 key={o.MenuID + idx}
                                // ref={o.FormID}
                                 onClick={() => {
                                    this.onMenuClick(o);
                                 }}>
                                <div className={"menu-item-icon"} style={{width: 22, height: 22}}>
                                    <InlineSVG src={this._getResourceImage(o.MenuID + '.svg')}/>
                                </div>
                                <div className={"nav-item-name"}>
                                    {o.FormDesc || o.MenuName}
                                </div>
                            </div>
                        </Tooltip>
                    </Animated>
                </div>
            </ul>
        )
    }
}

export default withStyles(styles, {withTheme: true})(SideNav);
