/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/container/index.js
 */

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

import * as mainActions from '../redux/main/main_actions';
import Popup from '../components/popup/popup';
import Config from '../config';
import Header from '../components/header/header';
import LoginPage from "../components/login/login";
import {browserHistory} from "react-router";
import SideNav from "../components/side-nav/side-nav";
import LocalizedStrings from "react-localization";
import Notify from "../components/common/notify/notify";
import Controller          from "../config/controller";
import EventTracking       from "../components/common/tracking/event";
import * as generalActions from "../redux/general/general_actions";
import NotPermission       from '../components/not-permission/not-permission';
import {Loading}           from "../components/common/loading/loading";
import AuthPage            from "../components/login/auth";
import {localize}          from "../localize/localize";
import Maintenance         from "../components/common/layouts/maintenance";
import moment              from "moment";

class Index extends Component {


    constructor(props){
        super(props);

        this.state = {
            expandSideBar: false,
            opened: true,
            isTab: Config.menuType,
            iPermission: 0,
        };
        this.loading = true;
        this.timer = null;
    }

    componentDidMount = async () => {
        const {children} = this.props;
        document.addEventListener('mousedown', this.outClickSideBar);
        Config.setLangDevextreme();
        this.loadLocalize();
        await this.changedRouterEvent();

        const pathname = children.props.route && children.props.route.path ? children.props.route.path : "";
        const isAdmin = pathname && pathname.indexOf("admin") !== -1;
        const stAdmin = Config.getLocalStorage('STADMINDHR');
        if (!isAdmin && stAdmin) {
            Config.setLocalStorage('STADMINDHR', '');
            Config.logout();
            return false;
        }
        this.redirectPage();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {iPermission} = this.props;
        const _loading = document.getElementById("_preloader_loading");
        if (_loading) {
            _loading.style.display = !!iPermission ? "none" : "block";
        }
    }

    redirectPage = () => {
        let pathinfo = Config.getLocalStorage('AUTHREDIRECTDHR');
        if (pathinfo) {
            pathinfo = JSON.parse(pathinfo);
            Config.removeLocalStorage('AUTHREDIRECTDHR');
            browserHistory.push({
                pathname: pathinfo.pathname || "",
                state: pathinfo.state
            });
        }
    };

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.outClickSideBar);
        this.timer = null;
    }

    outClickSideBar = (e) => {
        const btnToogle = document.getElementById('toogle-nav');
        if (this.refs && this.refs['sideBar'] && this.refs['sideBar'].refs['side-container'] && this.refs['sideBar'].refs['side-container'].childNodes.length > 0
            && !this.refs['sideBar'].refs['side-container'].childNodes[0].contains(e.target) && !btnToogle.contains(e.target)) {
            this.setState({
                expandSideBar: false
            })
        }
    };

    changedRouterEvent = async () => {
        const pathname = window.location.pathname;
        const formID = Config.helpers.getFormIDFromPath(pathname);
        Config.formID = formID;
        Config.setLocalStorage('formActive', JSON.stringify(Config.helpers.updateCurrentForm(formID)));
        await browserHistory.listen( async (location) =>  {
            const formID = Config.helpers.getFormIDFromPath(location.pathname);
            if (formID !== Config.formID || (!formID || formID === '/' || formID === 'dashboard')) {
                this.props.generalActions.resetPermission();
            }
            Config.formID = formID;
            Config.setLocalStorage('formActive', JSON.stringify(Config.helpers.updateCurrentForm(formID)));
        });
    };

    loadLocalize = () => {
        let cfLocalize = null;
        let lang = Config.getLocalStorage("langDHR");

        if (!lang || lang.length !== 2){
            lang = "vi";
            Config.setLocalStorage('langDHR','vi');
        }

        try {
            const lc = Config.getLocalStorage('LOCALIZE');
            cfLocalize = lc ? JSON.parse(lc) : null;

            if (!cfLocalize || cfLocalize.timestamps !== localize.timestamps) {
                cfLocalize = localize;
                Config.setLocalStorage('LOCALIZE',JSON.stringify(cfLocalize));
            }

        } catch (e) {

        }

        Config.localization = new LocalizedStrings(cfLocalize);
        Config.localization.setLanguage(lang);

        if (lang === "vi"){
            Config.language = "84";
        } else {
            Config.language = "01";
        }
    };

    UNSAFE_componentWillMount(){
        let profile = Config.decryptData(Config.getLocalStorage('PROFILEDHR'));
        profile = profile ? JSON.parse(profile) : null;
        if(profile){
            const isHcs = profile.IsHcsPermission && window.location.href.includes('hcs');
            const isPortal = profile.IsPortalPermission && window.location.href.includes('portal');
            const errHcs = profile.IsHcsPermission && !window.location.href.includes('hcs');
            const errPortal = profile.IsPortalPermission && !window.location.href.includes('portal');
            if(!isHcs && !isPortal && errHcs && errPortal){
                window.location.href = Config.getRootPath();
            }
        }
        this.props.mainActions.loading();
        this.props.generalActions.resetPermission();
        this.setState({isTab: Config.menuType});

        //setTimeoutCheckToken...
        this.setTimeoutToken(60 * 60 * 1000);
    }

    setTimeoutToken = (time) => {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.props.mainActions.checkToken();
            this.setTimeoutToken(time ? time : 3600000);
        }, time ? time : 3600000);
    };

    onLogout = () => {
        this.props.generalActions.resetPermission();
        Config.logout();
    };

    toggleMenuEss = (number, flag) => {
        this.setState({
            isTab: number
        },()=>{
            Config.setLocalStorage('MENUTYPEDHR', number);
            Config.menuType = number;
            if(flag) {
                browserHistory.push({
                    pathname: Config.getRootPath() + 'dashboard',
                    state: {
                        mode: !number ? 'ESS' : 'MSS'
                    }
                });
            }
        })
    };

    toogleSideBar = () => {
        this.setState({
            expandSideBar: !this.state.expandSideBar
        })
    };

    checkMaintenance = () => {
        const keyFrom = "DateFrom";
        const keyTo = "DateTo";
        const {maintenance} = this.props;
        let result = null;
        if (maintenance && maintenance[keyFrom]) {
            const now = moment();
            //Check current time greater than from time..
            const checkFrom = now.diff(moment(maintenance[keyFrom])) >= 0;
            //Check has't to or current time less than to time..
            const checkTo = !maintenance[keyTo] || now.diff(moment(maintenance[keyTo])) <= 0;
            result = checkFrom && checkTo ? maintenance : null;
        }
        return result
    };

    render() {
        const {children, token, setting, profile, iPermission} = this.props;
        const {isTab, expandSideBar} = this.state;

        let permission = false;

        if(token && token.id && setting && setting.length > 0) {
            this.loading = false;
        }
        if(profile && profile.type !== 0) {
            permission = profile.type;
        }
        const listExpandContent = Config.helpers && Config.helpers.listExpandContent ? Config.helpers.listExpandContent : ["W75F2000", "W09F2000"];
        const listFormIgnoreTracking = Config.helpers && Config.helpers.listFormIgnoreTracking ? Config.helpers.listFormIgnoreTracking : [];
        const pathname = children.props.route.path;
        this.isAdmin = pathname && pathname.indexOf("admin") !== -1;
        const arrRouterPublic = ["report", 'communication'];

        // check maintenance
        let maintenance = null;
        const url = new window.URLSearchParams(window.location.search);
        let pass = url.get('pass');
        let localDeployer = Config.getLocalStorage('DEPLOYER_SESSION');
        localDeployer = localDeployer && Config.isJson(localDeployer) ? JSON.parse(localDeployer) : null;

        if(!pass && localDeployer?.pass && moment(localDeployer.time).isAfter(moment())){
          pass = localDeployer?.pass;
        }

        if(Config.deployer !== pass){
            maintenance = this.checkMaintenance();
        }

        if(pass){
          Config.setLocalStorage('DEPLOYER_SESSION', JSON.stringify({pass:pass, time: moment().add(4,'hours')}))
        }

        if((!pass && localDeployer) || (maintenance?.DateTo && moment(maintenance.DateTo).isBefore(moment()))){
          Config.removeLocalStorage('DEPLOYER_SESSION');
        }

        if(this.isAdmin){
            window.location.replace(`${Config.env?.api}/admin`);
        }


        return (
            <div className="display_col full_w">
                <Controller ref={(ref) => Config.controller = ref} />
                {this.loading &&  (
                    <Loading />
                )}

                {!this.isAdmin && !arrRouterPublic.includes(pathname) && pathname === "auth" &&
                    <div className="display_col">
                        <AuthPage loading={this.loading}/>
                    </div>
                }

                {!this.isAdmin && maintenance &&
                    <div className="display_col">
                        <Maintenance data={maintenance}/>
                    </div>
                }

                {!this.isAdmin && !maintenance ? (
                    <>
                        {!this.isAdmin && !this.loading && !permission && !arrRouterPublic.includes(pathname) &&
                            <div className="display_col">
                                <LoginPage/>
                            </div>
                        }

                        {((!this.loading && arrRouterPublic.includes(pathname)) || (profile && !this.loading && permission)) &&
                            <div className="body-container">
                                {profile && profile.UserID && listFormIgnoreTracking.indexOf(pathname) < 0 &&
                                    <EventTracking category={"TRACKING_USER"} action={pathname ? pathname : "/"} label={profile.UserID} />
                                }

                                {arrRouterPublic.includes(pathname) &&
                                    <div className="body-container_right display_col">
                                        {children}
                                        <Notify ref={(ref) => Config.notify = ref}/>
                                    </div>
                                }

                                {!arrRouterPublic.includes(pathname) && <div className="body-container_right display_col">
                                    <Header onLogout={this.onLogout} isTab={isTab}
                                            toggleMenuEss={this.toggleMenuEss}
                                            toogleSideBar={this.toogleSideBar}
                                            {...this.props}
                                    />
                                    <div className={'display_row side-nav'}>
                                        {((pathname && pathname !== 'dashboard') || window.innerWidth < 440) &&
                                            <SideNav ref={'sideBar'} toggleMenuEss={this.toggleMenuEss}
                                                     expand={expandSideBar}
                                                     className={(listExpandContent.indexOf(pathname) > -1 ? "not-shadow" : "")}
                                                     onLogout={this.onLogout}
                                                     isTab={isTab}
                                                     {...this.props}
                                            />
                                        }
                                        {iPermission === 0 && !pathname.includes('W09F9000') && <div className={"content-container"}>
                                            <NotPermission/>
                                        </div>
                                        }
                                        {iPermission !== 0 && <div className={"content-container" + (listExpandContent.indexOf(pathname) > -1 ? " expand" : "")}>
                                            {children}
                                            <Notify ref={(ref) => Config.notify = ref}/>
                                            <Notify ref={(ref) => Config.notifyError = ref} minWidth={"50%"} anchorVertical={"top"} anchorHorizontal={"center"}/>
                                        </div>}
                                    </div>
                                </div>
                                }
                            </div>
                        }

                        {!this.loading && pathname === "error-page" && !profile && !permission && iPermission > 0 &&
                        <div className="body-container">
                            <div className="body-container_right display_col">
                                {children}
                            </div>
                        </div>
                        }
                    </>
                ) : null}

                <Popup ref={(ref) => {Config.popup = Config.popup2 = ref}} />
            </div>
        );
    }
}

export default connect(state => ({
        token: state.main.token,
        setting: state.main.setting,
        profile: state.main.profile,
        maintenance: state.main.maintenance,
        iPermission: state.general.iPermission,
    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
    })
)(Index);
