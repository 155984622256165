/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @update 2017/08/12 10:37
 */

import React from 'react';
import Config from '../../config';
import Tooltip from '../common/tooltip/tooltip';
import { Image } from 'react-bootstrap';
import { browserHistory } from "react-router";
import Profile from "./profile";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as mainActions from "../../redux/main/main_actions";
import Search from "../filter/search";
import InlineSVG from "react-inlinesvg";
import ActionToolbar from "../common/toolbar/action-toolbar";
import { Tab, Tabs, Button, CircularProgress } from "@material-ui/core";
import * as Firebase from "./push-notification";
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';
import LinkProducts from "../common/link-products/link-products"
import W09F7000 from "../W0X/W09/W09F7000/W09F7000"

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabNotify: "ALL",
            notifyLoading: true,
            totalNew: 0,
        };
        this.loadFullNotify = false;
        this.limitNotify = 10;
        this.scrollTop = 0;
        this.dataLoadNotify = {
            limit: this.limitNotify,
            skip: 0
        }
    }

    getDevice = () => {
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
            return 'Opera';
        }
        else if (navigator.userAgent.indexOf("Chrome") !== -1) {
            return 'Chrome';
        }
        else if (navigator.userAgent.indexOf("Safari") !== -1) {
            return 'Safari';
        }
        else if (navigator.userAgent.indexOf("Firefox") !== -1) {
            return 'Firefox';
        }
        else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
            return 'IE';
        }
        else {
            return 'unknown';
        }
    };

    getListNotification = () => {
        const { tabNotify } = this.state;
        const param = {
            AppID: Config.env.productID || "",
            limit: this.dataLoadNotify.limit,
            skip: this.dataLoadNotify.skip,
            sort: JSON.stringify([{ "read": "ASC" }, { "createdAt": "DESC" }, { "updatedAt": "DESC" }, { "clearBadge": "ASC" }]),
            ...(tabNotify === "SPECIAL" || tabNotify === "NORMAL" ? { type: tabNotify } : {})
        };
        this.props.mainActions.getListNotification(param, (err, data) => {
            this.props.mainActions.getBadge();
            this.setState({
                notifyLoading: false,
                totalNew: data && data.totalNew ? data.totalNew : 0
            }, () => {
                if (data && data.rows && data.rows.length >= data.total) {
                    this.loadFullNotify = true
                }
                if (document.getElementsByClassName('popup-content-notify').length > 0) {
                    document.getElementsByClassName('popup-content-notify')[0].scrollTop = this.scrollTop;
                }
            });
        });
    };

    componentDidMount() {
        let login = Config.decryptData(Config.getLocalStorage('PROFILEDHR'));
        login = login ? JSON.parse(login) : null;
        // const notification = process && process.env && process.env.REACT_APP_NOTIFY ? process.env.REACT_APP_NOTIFY : 'YES';
        if (login) {
            const config = Config.getSetting("FIREBASE_UI_CONFIG");
            if (config) {
                Firebase.fbInitializeApp();
                Firebase.fbRequestPermission((error, token) => {
                    if (token) {
                        Config.deviceToken = token;
                        let params = {
                            md: this.getDevice(),
                            token: token,
                            os: 'WEB',
                            language: Config.language
                        };
                        this.props.mainActions.addDevice(params);
                    }
                });

                Firebase.fbOnMessage((error, payload) => {
                    this.props.mainActions.getBadge();
                    const elNotify = document.querySelector('.popup-notify');
                    if (elNotify) {
                        this.getListNotification();
                    }
                });
            }

            this.props.mainActions.getBadge();

            // this.props.mainActions.listUser({Language: Config.language || "84"}, (errlistUser) => {
            //     if (errlistUser) {
            //         let message = errlistUser.message || Config.lang("CRM_Loi_chua_xac_dinh");
            //         Config.popup.show('INFO', message);
            //         this.setState({ isSubmit: false, message: message });
            //         return false;
            //     }
            // });
        }
    }

    toggleMenuESS = (number, flag = true) => {
        const { toggleMenuEss } = this.props;
        // if(isTab === number) return;
        if (toggleMenuEss) toggleMenuEss(number, flag);
    };

    toggleNav = () => {
        const { toogleSideBar } = this.props;
        if (toogleSideBar) toogleSideBar();
    };

    onChangeTabNotify = (e, value) => {
        this.dataLoadNotify = {
            limit: this.limitNotify,
            skip: 0
        };
        this.scrollTop = 0;
        this.loadFullNotify = false;
        this.setState({
            tabNotify: value,
            notifyLoading: true
        }, () => {
            this.getListNotification();
        });
    };

    onClickNotify = (e) => {
        if (e && e.form_id) {
            if (this.notify) {
                this.notify.hide();
            }
            const FormID = e && e.FormParentID ? e.FormParentID : e.form_id || "";
            this.toggleMenuESS(Number(e.Type ?? 0), false);
            browserHistory.push({
                pathname: Config.getRootPath() + FormID,
                state: {
                    ...e,
                    voucher_id: e && e.voucher_id ? e.voucher_id : ""
                }
            });
        }
    };

    onOpenedNotify = (e) => {
        e.style.top = (document.documentElement.scrollTop + e.offsetTop);
        document.documentElement.style.overflow = "hidden";
        this.getListNotification();
    };

    onClosedNotify = (e) => {
        // this.props.mainActions.updateBadge(0);
        document.documentElement.style.overflow = "auto";
    };

    handleScroll = (e) => {
        this.scrollTop = e.target.scrollTop ? e.target.scrollTop : this.scrollTop;
        let scrollHeight = e.target.offsetHeight;
        let viewHeight = this.listItemNotify.clientHeight;
        if (this.scrollTop + scrollHeight >= viewHeight) {
            if (this.state.notifyLoading || this.loadFullNotify) return;
            this.setState({
                notifyLoading: true
            }, () => {
                this.dataLoadNotify.limit += this.limitNotify;
                this.getListNotification();
            })
        }
    };

    redirectToGoalMenu = (pathname, data) => {
        this.toggleMenuESS(2, false);
        if (pathname !== data.FormCall) {
            browserHistory.push(Config.getRootPath() + data.FormCall);
        }
    };

    render() {
        const { isTab, children, badge, listNoty } = this.props;
        const { tabNotify, notifyLoading } = this.state;
        const pathname = children.props.route.path;
        const logoImg = Config.getSetting('LOGO_URL') ? Config.getSetting('LOGO_URL') : require('../../assets/images/general/logodhr.svg');

        let dataMenu = null;
        if (Config.menu) {
            dataMenu = Config.menu.listESS ? Config.menu.listESS : null;
        }
        dataMenu = !dataMenu ? [] : dataMenu;
        const transactionMenu = dataMenu.find(i => i.MenuGroupID === "Transaction");
        const goalMenu = !!transactionMenu && transactionMenu.MenuItem.find(i => i.MenuID === "MHRP39N0008");

        return (
            <div className="header-container">

                <div className={'display_row align-center view-logo'}>
                    <div className={'toogle-nav'} id={'toogle-nav'}>
                        <i onClick={this.toggleNav}>
                            <InlineSVG
                                className={(!pathname || pathname === '' || pathname === 'dashboard') && window.innerWidth > 370 ? ' disabled' : ''}
                                src={require('../../assets/images/header/menu-button.svg')} />
                        </i>
                        {/*<i className={"fas fa-bars" + ((!pathname || pathname === '' || pathname === 'dashboard') && window.innerWidth > 370 ? ' disabled' : '')}*/}
                        {/*   onClick={this.toggleNav}/>*/}
                    </div>
                    <div className={'display_row align-center logo'} onClick={() => {
                        this.toggleMenuESS(Number(Config.getLocalStorage('MENUTYPEDHR')));
                        browserHistory.push({
                            pathname: Config.getRootPath() + 'dashboard',
                            state: {
                                mode: !isTab ? 'ESS' : 'MSS'
                            }
                        })
                    }}>
                        {Config.getSetting('LOGO_URL') && <Image src={logoImg} />}
                        {!Config.getSetting('LOGO_URL') && <React.Fragment>
                            <Image src={logoImg} />
                            <span style={{ marginLeft: 5, fontSize: '1.125rem' }}>LemonHR</span>
                        </React.Fragment>
                        }
                    </div>
                    <div className={'header-filter'}>
                        <Search ref={ref => Config.filters = ref} />
                    </div>
                </div>
                <div className={'display_row align-center'}>
                    <W09F7000 toggleMenuESS={this.toggleMenuESS}/>
                    {goalMenu && <div onClick={(e) => this.redirectToGoalMenu(pathname, goalMenu)} className="item-view">
                        <Image style={{ width: '1.1rem' }} src={require(`../../assets/images/menu/${goalMenu.MenuID}.svg`)} />
                    </div>}
                    <div onClick={() => pathname !== "W09F9000" ? browserHistory.push(Config.getRootPath() + 'W09F9000') : false} className="item-view">
                        <Image style={{ width: '1.1rem' }} src={require('../../assets/images/header/help.svg')} />
                    </div>
                    <div className={'display_row align-center switch-menu'}>
                        <Button onClick={() => this.toggleMenuESS(0)}
                            variant={isTab === 0 ? 'contained' : 'text'}
                            color={!isTab ? 'primary' : 'inherit'}
                            className={'switch-menu-btn'}
                        >
                            ESS
                        </Button>
                        <Button onClick={() => this.toggleMenuESS(1)}
                            variant={isTab === 1 ? 'contained' : 'text'}
                            color={isTab ? 'primary' : 'inherit'}
                            className={'switch-menu-btn'}
                        >
                            MSS
                        </Button>
                    </div>
                    <Tooltip onEntered={this.onOpenedNotify} onExited={this.onClosedNotify} reference={ref => this.notify = ref} renderItem={() => {
                        return (
                            <>
                                <ActionToolbar
                                    style={{ marginTop: -5 }}
                                    className={"pdl15 pdr15"}
                                    alignment={"space-between"}
                                    height={48}
                                    onBack={this.onBack}
                                >
                                    <span className={"action-toolbar-title header-title-notify"}>{Config.lang("DHR_Thong_bao")}</span>
                                    <Tabs
                                        indicatorColor={"primary"}
                                        textColor={"primary"}
                                        style={{ minHeight: 28, marginBottom: -4 }}
                                        centered
                                        className={"header-tabs-notify"}
                                        value={tabNotify}
                                        onChange={this.onChangeTabNotify}
                                    >
                                        <Tab style={{ minWidth: 45 }} disabled={notifyLoading} value={"ALL"} label={Config.lang("DHR_Tat_ca")} />
                                        <Tab style={{ minWidth: 40 }} disabled={notifyLoading} value={"SPECIAL"} icon={<InlineSVG style={{ position: 'relative', top: -1 }} src={require("../../assets/images/general/star.svg")} />} />
                                        <Tab style={{ minWidth: 40 }} disabled={notifyLoading} value={"NORMAL"} icon={<InlineSVG src={require("../../assets/images/general/notify.svg")} />} />
                                    </Tabs>
                                </ActionToolbar>
                                <div className={"display_col popup-content-notify"} onScroll={this.handleScroll}>
                                    {notifyLoading && <div className={"wrapper-loading"}>
                                        <CircularProgress color="primary" />
                                    </div>}
                                    <div ref={ref => this.listItemNotify = ref}>
                                        {listNoty && listNoty.rows && listNoty.rows.length > 0 ? (
                                            listNoty.rows.map((notify, idx) => {
                                                const item = notify && notify.payload ? notify.payload : {};
                                                const user = Config.getUser({ UserID: notify.sender ? notify.sender : "" });
                                                const avatar = user && user.UserPictureURL ? user.UserPictureURL : (notify.URL ? Config.getCDNPath() + notify.URL : require('../../assets/images/general/user_default.svg'));
                                                const sender_name = user && user.EmployeeName ? user.EmployeeName : item.sender_name;
                                                return (
                                                    <div key={idx} className={"item-notify display_row " + (notify.read === 'Y' ? 'is-read' : '')} onClick={() => this.onClickNotify(item)}>
                                                        <img className={"item-notify-avatar"} alt="" src={avatar} />
                                                        <div className="display_col">
                                                            <div className="sender_name">
                                                                <b>{sender_name}</b>&nbsp;{Config.language === "01" ? item.voucher_desE : item.voucher_des}&nbsp;<b>{item.voucher_no}</b>
                                                            </div>
                                                            <div className="time">{Config.convertDate(notify.createdAt, "", "DD/MM/YYYY LT")}</div>
                                                        </div>
                                                        {idx !== listNoty.rows.length - 1 && <hr className="line-end" />}
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className={"display_row valign-middle pdt15 pdb15"}>
                                                <div className="text-center">
                                                    <span className="maxline-two text-muted">{Config.lang("DHR_Khong_co_thong_bao")}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={"display_row align-center valign-middle"}>
                                    <ExpandMoreIcon color={listNoty && listNoty.rows && listNoty.rows.length < listNoty.total ? "action" : "disabled"} />
                                </div>
                            </>
                        );
                    }}
                        renderCursor={() => {
                            return (
                                <div className="item-view">
                                    <Image style={{ width: '1.1rem' }} src={require('../../assets/images/general/notification.svg')} />
                                    {!!badge && <span>{badge}</span>}
                                </div>
                            )
                        }}
                        className="popup-notify popup-tooltip"
                    // footer={Config.lang("DHR_Xem_them")}
                    />

                    {/*<Image className={'logo-company'} style={{width: 80, height: 27}} alt="" src={require('../../assets/images/logo.png')}/>*/}
                    {/* Link to products */}
                    <LinkProducts />

                    <div className="profile-view">
                        <Profile />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
    filters: state.main.filters,
    badge: state.main.badge,
    listNoty: state.main.listNoty,
}),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch)
    })
)(Header);
