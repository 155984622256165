/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/11/2020
 * @Example
 */

import React        from "react";
import {withStyles} from "@material-ui/core";
import PropTypes    from "prop-types";
import {compose}    from "redux";
import Config       from "../../config";

const styles = {
    root: {
        // opacity: 0
        display: "inline-block"
    },
};

class GridActionBar extends React.Component {
    constructor(props) {
        super(props);
        this.actionbar = null;
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.actionbar && !Config.isMobile) {
                this.actionbar.classList.remove("grid-action-opacity");
                const grid = this.actionbar.closest(".dx-datagrid");
                const container = this.actionbar.closest(".dx-datagrid-content");
                let height = this.actionbar.offsetHeight + "px";
                if (container) {
                    if (container.classList.contains("dx-datagrid-content-fixed")) {
                        const td          = this.actionbar.closest("td");
                        const width       = this.actionbar.offsetWidth + 30 + "px";
                        const colIndex = Number(td.getAttribute("aria-colindex"));
                        height            = td.offsetHeight + "px";
                        td.style.width    = width;
                        td.style.minWidth = width;
                        td.style.maxWidth = width;
                        td.classList.add("grid-action-hover");

                        //process header...
                        const containerHeader = grid.querySelectorAll(".dx-datagrid-headers .dx-datagrid-content");
                        if (containerHeader && containerHeader.length > 0) {
                            containerHeader.forEach(cont => {
                                if (cont.classList.contains("dx-datagrid-content-fixed")) {
                                    const table = cont.querySelector(".dx-datagrid-table-fixed");
                                    if (table) {
                                        table.style.height = "100%";
                                        table.querySelector("colgroup col:nth-child("+colIndex+")").style.width = 0;
                                        const td = table.querySelector('tbody tr td[aria-colindex="'+colIndex+'"]');
                                        if (td) td.classList.add("grid-action-hover");
                                    }
                                    // cont.querySelectorAll("table tbody tr td:last-child").forEach(td => td.classList.add("grid-action-hover"));
                                } else {
                                    const table = cont.querySelector(".dx-datagrid-table-fixed");
                                    const td = table.querySelector('tbody tr td[aria-colindex="'+colIndex+'"]');
                                    if (td) td.classList.remove("dx-hidden-cell");
                                    // cont.querySelectorAll("table tbody tr td:last-child").forEach(td => td.classList.remove("dx-hidden-cell"));
                                }
                            });
                        }
                        container.querySelectorAll(".dx-freespace-row td:last-child").forEach(t => {
                            if (!t.classList.contains("grid-action-hover")) t.classList.add("grid-action-hover");
                        });
                    } else {
                        const td = this.actionbar.closest("td");
                        height   = td.offsetHeight + "px";
                        td.classList.remove("dx-hidden-cell");
                        this.actionbar.style.display = "none";
                    }
                    container.querySelectorAll("td").forEach(t => {
                        t.style.height = height;
                    });
                }
            }
        }, 600);
        window.addEventListener("resize", this.resizeWindow);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow);
    }

    resizeWindow = () => {
        if (this.actionbar && !Config.isMobile) {
            this.actionbar.classList.remove("grid-action-opacity");
            const td = this.actionbar.closest("td");
            td.classList.remove("grid-action-hover");
            let width = this.actionbar.offsetWidth + 30 + "px";
            const grid = this.actionbar.closest(".dx-datagrid");
            const container = this.actionbar.closest(".dx-datagrid-content");
            if (container) {
                if (container.classList.contains("dx-datagrid-content-fixed")) {
                    const td = this.actionbar.closest("td");
                    const colIndex = Number(td.getAttribute("aria-colindex"));
                    td.classList.remove("grid-action-hover");
                    width             = this.actionbar.offsetWidth + 30 + "px";
                    td.style.width    = width;
                    td.style.minWidth = width;
                    td.style.maxWidth = width;
                    td.classList.add("grid-action-hover");

                    //process header...
                    const containerHeader = grid.querySelectorAll(".dx-datagrid-headers .dx-datagrid-content");
                    if (containerHeader && containerHeader.length > 0) {
                        containerHeader.forEach(cont => {
                            if (cont.classList.contains("dx-datagrid-content-fixed")) {
                                const table = cont.querySelector(".dx-datagrid-table-fixed");
                                if (table) {
                                    table.style.height = "100%";
                                    table.querySelector("colgroup col:nth-child("+colIndex+")").style.width = 0;
                                    const td = table.querySelector('tbody tr td[aria-colindex="'+colIndex+'"]');
                                    if (td) td.classList.add("grid-action-hover");
                                }
                                // cont.querySelectorAll("table tbody tr td:last-child").forEach(td => td.classList.add("grid-action-hover"));
                            } else {
                                const table = cont.querySelector(".dx-datagrid-table-fixed");
                                const td = table.querySelector('tbody tr td[aria-colindex="'+colIndex+'"]');
                                if (td) td.classList.remove("dx-hidden-cell");
                                // cont.querySelectorAll("table tbody tr td:last-child").forEach(td => td.classList.remove("dx-hidden-cell"));
                            }
                        });
                    }

                    //process body..
                    const table = container.querySelector(".dx-datagrid-table-fixed");
                    if (table) {
                        table.style.height = "100%";
                        table.querySelector("colgroup col:nth-child("+colIndex+")").style.width = width;
                    }
                } else {
                    const td                     = this.actionbar.closest("td");
                    const colIndex = Number(td.getAttribute("aria-colindex"));
                    this.actionbar.style.display = "inline-block";
                    width                        = this.actionbar.offsetWidth + 30 + "px";
                    td.classList.remove("dx-hidden-cell");
                    this.actionbar.style.display = "none";

                    //process body..
                    const table = container.querySelector(".dx-datagrid-table-fixed");
                    if (table) {
                        table.style.height = "100%";
                        table.querySelector("colgroup col:nth-child("+colIndex+")").style.width = width;
                    }
                }
            }
        }
    };

    render() {
        const {classes, style, className, children} = this.props;
        const _className = !Config.isMobile ? "grid-action-opacity " + className : className;

        return (
            <div ref={ref => this.actionbar = ref} className={"fal-grid-actionbar " + classes.root + " " + _className}
                 style={style}
            >
                {children}
            </div>
        );
    }
}

GridActionBar.propTypes = {
    style: PropTypes.any,
    className: PropTypes.string,

};

export default compose(withStyles(styles))(GridActionBar)
