/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/28/2019
 * @Example
 */

import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Config from "../../config";
import DataGrid, { Paging as DxPaging, Pager, Selection, KeyboardNavigation } from "devextreme-react/data-grid";
import Paging from "../grid-container/paging";
import GridToolbar from "../tool-bar/grid-toolbar";
import GridFilterBar from "../tool-bar/grid-filter";
import { LoadPanel } from 'devextreme-react/load-panel';

class GridContainer extends PureComponent {

    constructor(props) {
        super(props);
        const listPerPage = props.listPerPage ? props.listPerPage : [10, 20, 40, 50];
        this.state = {
            currentPage: props.isPage || props.isPage === 0 ? props.isPage : 0,
            itemPerPage: props.itemPerPage ? props.itemPerPage : listPerPage[0],
            allowedPageSizes: listPerPage,
            _totalItems: props.dataSource ? props.dataSource.length : 0,
            deselectKey: [],
            selectKey: [],
            selectRowData: []
        };
        this.isSubmit = false;
        this.isFiltered = false;
        this.filterGrid = {};
        this.grid = null;
        this.paging = React.createRef();
        this.selectedRange = {};
        this.isSelectionStopped = true;
        this.fieldSort = "";
        this.typeSort = "";
        this.cellHeader = null;
        this.isSelecting = false;
        this.filterColField = "";
    }


    cellClick(e) {
        // Bi loi khi chay cung voi rowclick, chua fix
        if (!this.props.onCellClick && !this.props.onDbCellClick) return false;
        let component = e.component;
        let prevClickTime = component.lastClickTime;
        component.lastClickTime = new Date();
        if (prevClickTime && (component.lastClickTime - prevClickTime < 300) && this.props.onDbCellClick) {
            this.props.onDbCellClick(e);
        } else if (this.props.onCellClick) {
            this.props.onCellClick(e);
        }

        const {isOrderAPI, onOrderChanged} = this.props;
        if (isOrderAPI && onOrderChanged) {
            if (e.rowType === "header") {
                const {column} = e;
                if (this.fieldSort !== column.dataField) {
                    this.fieldSort = column.dataField;
                    this.typeSort = "ASC";
                } else {
                    this.typeSort = this.typeSort === "ASC" ? "DESC" : (this.typeSort === "DESC" ? "ASC" : "ASC");
                }
                this.cellHeader = e.cellElement;

                // this.setOrderChanged(e);

                if (onOrderChanged) onOrderChanged({...e, column: column, typeSort: this.typeSort.toUpperCase()});
            }
        }
    }

    rowClick(e) {
        if (!this.props.onDbRowClick && !this.props.onRowClick) return false;
        let component = e.component;
        let prevClickTime = component.lastClickTime;
        component.lastClickTime = new Date();
        if (prevClickTime && (component.lastClickTime - prevClickTime < 300) && this.props.onDbRowClick) {
            this.props.onDbRowClick(e);
        } else if (this.props.onRowClick) {
            this.props.onRowClick(e);
        }
    }

    showSelection = (component, selectedRange) => {
        const {disabledCellSelection, onCellSelectionChanged} = this.props;
        const cellSelected = component.element().querySelectorAll(".cell-selected");
        cellSelected.forEach(cell => cell.classList.remove("cell-selected"));

        if (onCellSelectionChanged && !disabledCellSelection) onCellSelectionChanged({component: component, selectedRange: selectedRange});

        this.foreachRange(selectedRange, (rowIndex, columnIndex) =>  {
            if (component.getCellElement(rowIndex, columnIndex)) {
                component.getCellElement(rowIndex, columnIndex).classList.add("cell-selected");
            }
        });
    };
    foreachRange = (selectedRange, cb) =>  {
        if(selectedRange.startRowIndex >= 0) {
            var minRowIndex = Math.min(selectedRange.startRowIndex, selectedRange.endRowIndex);
            var maxRowIndex = Math.max(selectedRange.startRowIndex, selectedRange.endRowIndex);
            var minColumnIndex = Math.min(selectedRange.startColumnIndex, selectedRange.endColumnIndex);
            var maxColumnIndex = Math.max(selectedRange.startColumnIndex, selectedRange.endColumnIndex);

            for(var rowIndex = minRowIndex; rowIndex <= maxRowIndex; rowIndex++) {
                for(var columnIndex = minColumnIndex; columnIndex <= maxColumnIndex; columnIndex++) {
                    cb(rowIndex, columnIndex);
                }
            }
        }
    };

    // selectRow(e) {
    //     //Khong can phai khai bao keyID
    //     const selectedRowData = e.selectedRowsData; //array chứa những dòng selected
    //     let deselectedKey = e.currentDeselectedRowKeys; //array chứa những dòng unselected
    //     let selectVal = [...this.state.selectKey]; //array chứa key
    //     let selectRow = [...this.state.selectRowData];// array chua rowData
    //     let i;
    //     let gridContain = [];// array chứa những dòng trong datasource === deselectedKey
    //     const keyExpr = e.component.option('keyExpr');
    //     const gridData = e.component.option('dataSource');
    //
    //     //Quét for array selectedKey ,nếu ko có trong array selectVal thì add vào
    //     for (i = 0; i < selectedRowData.length; i++) {
    //         let j = 0;
    //         let isContinue = false;
    //         for (j = 0; j < selectVal.length; j++) {
    //             if ((keyExpr ? selectVal[j] : JSON.stringify(selectVal[j])) === (keyExpr ? selectedRowData[i][keyExpr] : JSON.stringify(selectedRowData[i]))) {
    //                 isContinue = true;
    //                 break;
    //             }
    //         }
    //         if (isContinue) continue;
    //         selectVal.push(keyExpr ? selectedRowData[i][keyExpr] : selectedRowData[i]);
    //         selectRow.push(selectedRowData[i]);
    //     }
    //     //Nếu change page hay search thì ko delete giá trị  theo array deselectedKey
    //     if (deselectedKey.length > 0) { //Chỉ delete khi datasouce có chứa giá trị
    //         for (i = 0; i < deselectedKey.length; i++) {
    //             for (let j = 0; j < gridData.length; j++) {
    //                 if ((keyExpr ? deselectedKey[i] : JSON.stringify(deselectedKey[i])) === (keyExpr ? gridData[j][keyExpr] : JSON.stringify(gridData[j]))) {
    //                     gridContain.push((keyExpr ? gridData[j][keyExpr] : gridData[j]))
    //                 }
    //             }
    //         }
    //         if (JSON.stringify(gridContain.sort()) === JSON.stringify(deselectedKey.sort())) {
    //             for (i = deselectedKey.length - 1; i >= 0; i--) {
    //                 let j = 0;
    //                 for (j = selectVal.length - 1; j >= 0; j--) {
    //                     if ((keyExpr ? selectVal[j] : JSON.stringify(selectVal[j])) === (keyExpr ? deselectedKey[i] : JSON.stringify(deselectedKey[i]))) {
    //                         selectVal.splice(j, 1);
    //                         selectRow.splice(j, 1);
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     this.setState({
    //         selectKey: [...selectVal],
    //         selectRowData: [...selectRow]
    //     }, () => {
    //         const {selectRowData} = this.state;
    //         const {onSelectionChanged} = this.props;
    //
    //         if (onSelectionChanged) {
    //             onSelectionChanged(e, selectRowData)
    //         }
    //     });
    //
    // }

    onFilterGrid(e) {
        let filter = e.component.getCombinedFilter(true);
        const { onFilterChanged } = this.props;
        let letFilter = false;
        let listFilter = {};
        let eventFilter = {};
        eventFilter.component = e.component;

        if (filter) {
            filter = !Array.isArray(filter[0]) ? [filter] : filter;
            filter.forEach((val) => {
                if (Array.isArray(val)) {
                    listFilter[val[0]] = val.filterValue;
                    if (this.filterGrid[val[0]] || this.filterGrid[val[0]] === 0) {
                        if (this.filterGrid[val[0]] !== val.filterValue) {
                            letFilter = true;
                        }
                    } else {
                        letFilter = true;
                    }
                }
            });
            if (Object.keys(this.filterGrid).length !== Object.keys(listFilter).length) {
                letFilter = true;
            }
            this.filterGrid = listFilter;
            if (letFilter) {
                this.isFiltered = true;
                eventFilter.filter = this.filterGrid;
                onFilterChanged(eventFilter);
            }
        } else {
            this.filterGrid = {};
            if (this.isFiltered) {
                this.isFiltered = false;
                eventFilter.filter = null;
                onFilterChanged(eventFilter);
            }
        }
    }

    componentWillUnmount() {
        this.selectKey = [];
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.itemPerPage) !== JSON.stringify(this.state.itemPerPage) || JSON.stringify(nextProps.isPage) !== JSON.stringify(this.state.isPage)) {
            this.setState({
                itemPerPage: nextProps.itemPerPage ?? this.state.itemPerPage,
                currentPage: nextProps.isPage
            });
        }
    }

    handleScroll = (e) => {
        if (this.isSubmit) return;
        const bottom = e.target.scrollTop && ((e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight + 30);
        if (bottom && this.props.loadMore) {
            this.isSubmit = true;
            this.props.loadMore(() => {
                this.isSubmit = false;
            });
        }
    };

    setPositionPaging = () => {
        if (this.grid && this.paging && this.paging.current) {
            const paging = this.paging.current;
            const offsetTop = paging.offsetTop + 30;
            const heightPaging = paging.offsetHeight;
            const _h = window.innerHeight;
            if (offsetTop + heightPaging > _h) {
                this.paging.current.classList.add('paging-fixed');
                // this.grid.style.height = 'calc(100% - ' + heightPaging + 'px)';
            } else {
                this.paging.current.classList.remove('paging-fixed');
                // this.grid.style.height = 'auto';
            }
        }
    };

    updateTotal = (e) => {
        const {typePaging} = this.props;
        const _typePaging = typeof typePaging === "undefined" ? (typeof totalItems !== "undefined" ? "remote" : "default") : typePaging;
        const _total = e.component.totalCount();
        if (this._paging && _typePaging === "normal") this._paging.setTotalItems(_total);
    };

    setOrderChanged = (e) => {
        if (!e) return false;
        if (this.typeSort && this.fieldSort && e.cellElement) {
            const {column} = e;
            if (column && column.dataField === this.fieldSort && e.rowType === "header") {
                const indicators = e.cellElement.getElementsByClassName("dx-column-indicators");
                if (indicators.length < 1) {
                    if (e.element.getElementsByClassName("dx-column-indicators").length > 0) {
                        e.element.getElementsByClassName("dx-column-indicators").forEach(ind => ind.remove());
                    }
                    e.cellElement.children[0].classList.add("dx-text-content-alignment-left", "dx-text-content-alignment-right", "dx-sort-indicator");
                    e.cellElement.innerHTML += '<div class="dx-column-indicators" role="presentation"><span class="dx-sort '+ (this.typeSort === "ASC" ? "dx-sort-up" : "dx-sort-down") +'" /></div>';
                } else {
                    const el_sort = e.cellElement.getElementsByClassName("dx-sort");
                    if (el_sort && el_sort.length > 0) {
                        if (this.typeSort === "DESC") {
                            el_sort[0].classList.remove("dx-sort-up");
                            el_sort[0].classList.add("dx-sort-down");
                        } else {
                            el_sort[0].classList.remove("dx-sort-down");
                            el_sort[0].classList.add("dx-sort-up");
                        }
                    }
                }
            }
        }
    };

    setModeSelection = () => {
        // truyền trực tiếp select={object} vào grid container sẽ làm ảnh hướng input bị chậm và chrome warning, set bằng component vào children thì không sao
        const { selection } = this.props;

        if (!selection) {
            return <Selection mode={'single'} />;
        }
        return <Selection {...selection} />;
    };

    getFilters = (filters) => {
        if (!(filters && filters.length)) return null;
        if (!filters.includes("and") && !filters.includes("or")) return [filters];
        let _filters = [];
        for(let f of filters) {
            if (Array.isArray(f)) {
                const _f = this.getFilters(f);
                if (_f) _filters = _filters.concat(_f);
            }
        }
        return _filters.length ? _filters : undefined;
    };

    onOptionChanged = (e) => {
        const {isOrderAPI, onOptionChanged, onOrderChanged} = this.props;
        if (e && e.name === "columns" && e.fullName.endsWith("sortOrder") && !isOrderAPI) {
            const fullName = e.fullName.split('.');
            if (fullName.length < 1) return false;
            const matchs = fullName[0].match(/columns\[(\d+)\]/);
            if (matchs.length < 2) return false;
            const colIndx = matchs[1];
            const columns = e.component.option("columns");
            const column = columns[colIndx];
            const typeSort =  e.value ? e.value : 'asc';
            if (onOrderChanged) onOrderChanged({...e, column: column, typeSort: typeSort.toUpperCase()});
            // setTimeout(() => {
            //     e.component.columnOption(column.dataField, 'sortOrder', typeSort);
            // }, 300);
        }

        if (e && e.name === "columns" && e.fullName.endsWith("filterValue")) {
            const {onFilterGrid} = this.props;
            if (onFilterGrid) {
                const fullName = e.fullName.split('.');
                if (fullName.length < 1) return false;
                let columns = e.component.option("columns");
                let colIndx = 0;
                let column = null;
                for(let i = 0; i < fullName.length - 1;i++) {
                    const matchs = fullName[i].match(/columns\[(\d+)\]/);
                    if (matchs.length >= 2) {
                        columns = column && column.columns ? column.columns : columns;
                        colIndx = matchs[1];
                        column = columns[colIndx]
                    }
                }
                const value = e.value;
                this.filterColField = column?.dataField;

                if (onFilterGrid) onFilterGrid({component: e.component, column, filterValue: typeof value === "string" ? value.normalize() : value, columnIndex: Number(colIndx)});
                // setTimeout(() => {
                //     e.component.columnOption(column.dataField, 'sortOrder', typeSort);
                // }, 300);
            }
        }

        if (e && e.name === "filterValue" && e.fullName === "filterValue" && e.value === null) {
            const {onFilterGrid} = this.props;
            if (onFilterGrid) onFilterGrid({component: e.component, column: null, filterValue: null});
        }

        if (onOptionChanged) onOptionChanged(e);
    };

    render() {
        let {
                dataSource, title, totalItems, skipPerPage, keyExpr, onSearch, onFilter, filterData,
                buttonCRM, selectedRowKey, typeShort, wordWrapEnabled, editing, columnResizingMode, filterRow,
                columnAutoWidth, style, allowSearch, loadMore, widthFull, disabled, typePaging,
                isPer, allowAdding, onAddNew, showSendMail, onSendMail, reference, id, onRowInserted,
                onRowUpdated, onRowRemoved, onRowUpdating, onEditingStart, onInitNewRow, onRowRemoving,
                onEditorPrepared, onEditorPreparing, children, onChangePage, onChangePerPage, valueSearch,
                onFilterChanged, onContentReady, height, loadPanel, paging, gridProps, onRowDblClick,
                showBorders, showColumnHeaders, showColumnLines, showRowLines, scrolling, hoverStateEnabled, loading,
                onSelectionChanged, allowColumnResizing, pagerFullScreen, onRowPrepared, pager, elementAttr,
                onFocusedCellChanging, onKeyDown, allowCellSelection, onInitialized, onCellHoverChanged, filterValue,
                filterPanel, filterBuilder, filterBuilderPopup, onCellSelectionChanged, disabledCellSelection, sorting, isOrderAPI,
                onCellPrepared, rowAlternationEnabled, onRowValidating, onFocusedCellChanged, onRowInserting, grouping, onContextMenuPreparing
            } = this.props;
        const {
                  currentPage, itemPerPage, allowedPageSizes, _totalItems
              } = this.state;

        const pageSize = paging && paging.pageSize ? paging.pageSize : (itemPerPage ? itemPerPage : 10);
        const _typePaging = typeof typePaging === "undefined" ? (typeof totalItems !== "undefined" ? "remote" : "default") : typePaging;
        const _sorting = isOrderAPI ? {mode: "none"} : (sorting ? sorting : {});

        //selectedRowKeys của thay đổi thuộc tính của object state.selectkey nên phải deep copy object này
        // const selectKey = this.state.selectKey.map(a => ({...a}));
        // totalItems = parseInt(totalItems, 10);

        return (
            <div ref={ref => this.grid = ref} className={"grid-container"}
                style={style ? style : {}}
                onScroll={loadMore ? this.handleScroll : null}>
                {title && <TitleGrid label={title} />}

                {onSearch &&
                    <GridToolbar disabled={disabled}
                        allowAdding={allowAdding}
                        allowSearch={allowSearch}
                        isPer={isPer}
                        buttonCRM={buttonCRM && buttonCRM}
                        valueSearch={valueSearch && valueSearch}
                        onSearch={(text) => {
                            onSearch && onSearch(text);
                        }} onAddNew={onAddNew}
                        showSendMail={showSendMail}
                        onSendMail={onSendMail} />}

                {onFilter && filterData &&
                    <GridFilterBar onFilter={onFilter} filterData={filterData} />}

                <LoadPanel
                    position={{ my: 'center', of: this.grid ? this.grid : null }}
                    visible={this.grid && loading}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                />

                <DataGrid
                    {...gridProps}
                    disabled={disabled ? disabled : (loading ? loading : false) }
                    id={id ? id : null}
                    ref={ref => {
                        this.dataGrid = ref;
                        reference && reference(ref)
                    }}
                    dataSource={dataSource}
                    keyExpr={keyExpr}
                    {...editing ? {editing: editing} : {}}
                    filterRow={filterRow && filterRow}
                    width={widthFull ? 'fit-content' : "100%"}
                    wordWrapEnabled={wordWrapEnabled ? wordWrapEnabled : false}
                    showRowLines={typeof showRowLines !== "undefined" ? showRowLines : true}
                    showBorders={typeof showBorders !== "undefined" ? showBorders : true}
                    showColumnHeaders={typeof showColumnHeaders !== "undefined" ? showColumnHeaders : true}
                    showColumnLines={typeof showColumnLines !== "undefined" ? showColumnLines : true}
                    loadPanel={loadPanel}
                    elementAttr={elementAttr}
                    height={height}
                    filterValue={filterValue}
                    defaultFilterValue={filterValue}
                    allowColumnReordering={true}
                    allowColumnResizing={allowColumnResizing}
                    noDataText={loading ? "" : Config.lang('DHR_Khong_co_du_lieu')}
                    columnAutoWidth={columnAutoWidth ? columnAutoWidth : true}
                    columnResizingMode={columnResizingMode ? columnResizingMode : 'nextColumn'}
                    repaintChangesOnly={true}
                    highlightChanges={true}
                    {...(filterPanel ? {filterPanel:{
                            texts: {clearFilter: Config.lang("DHR_Xoa")},
                            ...filterPanel
                        }} : {})
                    }
                    {...(filterBuilder ? {filterBuilder: filterBuilder} : {})}
                    {...(filterBuilderPopup ? {filterBuilderPopup:filterBuilderPopup} : {})}
                    scrolling={{
                        scrollByContent: true,
                        scrollByThumb: true,
                        ...scrolling
                    }}
                    {...(_sorting ? {sorting: _sorting} : {})}
                    {...(grouping ? {grouping: grouping} : {})}
                    onOptionChanged={this.onOptionChanged}
                    hoverStateEnabled={hoverStateEnabled}
                    onContentReady={(e) => {
                        this.updateTotal(e);
                        if (onFilterChanged) {
                            this.onFilterGrid(e);
                        }
                        if (onContentReady) {
                            onContentReady(e);
                        }
                        if (onCellSelectionChanged && !disabledCellSelection)
                            this.showSelection(e.component, this.selectedRange);
                    }}
                    onSelectionChanged={(e) => {
                        if (onSelectionChanged) {
                            onSelectionChanged(e)
                        }
                    }}
                    onRowInserting={(e) => {
                        if (onRowInserting) onRowInserting(e);
                    }}
                    onRowInserted={(e) => {
                        if (onRowInserted) onRowInserted(e);
                    }}
                    onRowUpdated={(e) => {
                        if (onRowUpdated) onRowUpdated(e);
                    }}
                    onRowRemoved={(e) => {
                        if (onRowRemoved) onRowRemoved(e);
                    }}
                    onRowUpdating={(e) => {
                        if (onRowUpdating) onRowUpdating(e);
                    }}
                    onEditingStart={(e) => {
                        if (onEditingStart) onEditingStart(e);
                    }}
                    onInitNewRow={(e) => {
                        if (onInitNewRow) onInitNewRow(e);
                    }}
                    onRowRemoving={(e) => {
                        if (onRowRemoving) onRowRemoving(e);
                    }}
                    onRowPrepared={(e) => {
                        if (onRowPrepared) onRowPrepared(e);
                    }}
                    onEditorPrepared={(e) => {
                        if (onEditorPrepared) onEditorPrepared(e);
                    }}
                    onEditorPreparing={(e) => {
                        if (onEditorPreparing) onEditorPreparing(e);
                    }}
                    onCellPrepared={(e) => {
                        if (onCellPrepared) onCellPrepared(e);
                        if(e.rowType === "filter") {// && e.column.dataField === "EmployeeID"
                            let filter = e.component.getCombinedFilter(true);
                            filter = this.getFilters(filter);
                            if (filter) {
                                let aText = [];
                                for (let f of filter) {
                                    if (Array.isArray(f)) {
                                        if (f[0] === e.column.dataField) aText.push(f[2]);
                                    }
                                }
                                if (e.cellElement.querySelector("input")) {
                                    if (aText.length) {
                                        e.cellElement.querySelector("input").value = aText.join(e?.separator ? e.separator : ";");
                                    }
                                    if (this.filterColField && this.filterColField === e?.column?.dataField) {
                                        if (this.timerFocus) clearTimeout(this.timerFocus);
                                        this.timerFocus = setTimeout(() => {
                                            if (e.cellElement.querySelector("input")) {
                                                e.cellElement.querySelector("input").focus();
                                            }
                                            this.filterColField = "";
                                        }, 300);
                                    }
                                }
                            }
                        }
                        this.setOrderChanged(e);
                    }}
                    onRowValidating={(e) => {
                        if (onRowValidating) onRowValidating(e);
                    }}
                    onContextMenuPreparing={(e) => {
                        if (onContextMenuPreparing) onContextMenuPreparing(e);
                    }}
                    onFocusedCellChanging={(e) => {
                        if (onFocusedCellChanging) {
                            onFocusedCellChanging(e);
                        } else {
                            e.isHighlighted = allowCellSelection;
                        }
                        if (allowCellSelection) {
                            const forcusOverlay = e.element.querySelector('.dx-datagrid-focus-overlay');
                            if (forcusOverlay) {
                                forcusOverlay.style.border = "2px solid #337ab7";
                            }
                        }
                    }}
                    onFocusedCellChanged={(e) => {
                        if (onFocusedCellChanged) onFocusedCellChanged(e);
                    }}
                    onCellHoverChanged={(e) => {
                        if (onCellHoverChanged) onCellHoverChanged(e);

                        //set selection range
                        const event = e.event;
                        if(event.buttons === 1 && onCellSelectionChanged && !disabledCellSelection) {
                            if(this.isSelectionStopped) {
                                this.isSelectionStopped = false;
                                this.selectedRange = {};
                            }
                            this.selectedRange.rowType = e && e.rowType ? e.rowType : "";
                            if(this.selectedRange.startRowIndex === undefined) {
                                this.selectedRange.startRowIndex = e.rowIndex;
                            }
                            if(this.selectedRange.startColumnIndex === undefined) {
                                this.selectedRange.startColumnIndex = e.columnIndex;
                            }

                            this.selectedRange.endRowIndex = e.rowIndex;
                            this.selectedRange.endColumnIndex = e.columnIndex;

                            this.showSelection(e.component, this.selectedRange);

                        }
                        else {
                            this.isSelectionStopped = true;
                        }
                    }}
                    onInitialized={onInitialized}
                    onKeyDown={onKeyDown}
                    rowAlternationEnabled={rowAlternationEnabled}
                    onCellClick={(e) => {
                        this.cellClick(e);

                        //set select ranger
                        if (onCellSelectionChanged && !disabledCellSelection) {
                            this.selectedRange.rowType = e.rowType;
                            if (e.rowType !== "header") {
                                this.selectedRange.startRowIndex = this.selectedRange.endRowIndex = e.rowIndex;
                                this.selectedRange.startColumnIndex = this.selectedRange.endColumnIndex = e.columnIndex;
                                this.isSelectionStopped = false;
                                this.showSelection(e.component, this.selectedRange);
                            }
                        }
                    }}
                    onRowClick={
                        (e) => {
                            this.rowClick(e);
                        }}
                    onRowDblClick={onRowDblClick}
                    selectedRowKeys={selectedRowKey}
                >
                    {this.setModeSelection()}
                    {/*<Scrolling columnRenderingMode={'virtual'}/>*/}
                    {children}
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction={'moveFocus'}
                        enterKeyDirection={'column'} />
                    <DxPaging defaultPageSize={_typePaging === "remote" ? totalItems : pageSize}
                        pageSize={_typePaging === "remote" ? totalItems : pageSize}
                        enabled={_typePaging === "remote" ? false : (paging && paging.enabled ? paging.enabled : true)}
                        defaultPageIndex={paging && paging.pageIndex ? paging.pageIndex : (currentPage ? currentPage : 0)}
                    />
                    <Pager visible={_typePaging !== "default" ? false : (pager && typeof pager.visible !== "undefined" ? pager.visible : dataSource && dataSource.length > pageSize)}
                        showInfo={pager && pager.showInfo ? pager.showInfo : false}
                        showNavigationButtons={pager && pager.showNavigationButtons ? pager.showNavigationButtons : false}
                        showPageSizeSelector={pager && pager.showPageSizeSelector ? pager.showPageSizeSelector : false}
                    />
                </DataGrid>

                {_typePaging === "remote" && !!totalItems && <Paging
                    ref={ref => this._paging = ref}
                    reference={this.paging}
                    typeShort={typeShort ? typeShort : false}
                    totalItems={totalItems}
                    skipPerPage={skipPerPage}
                    currentPage={currentPage}
                    itemPerPage={itemPerPage}
                    listPerPage={allowedPageSizes}
                    className={pagerFullScreen ? "paging-fixed" : ""}
                    onChangePage={(page) => {
                        if (onChangePage) onChangePage(page);
                    }}
                    onChangePerPage={(per) => {
                        if (onChangePerPage) onChangePerPage(per);
                    }}
                />}
                {_typePaging === "normal" && <Paging
                    ref={ref => this._paging = ref}
                    reference={this.paging}
                    typeShort={typeShort ? typeShort : false}
                    totalItems={_totalItems}
                    skipPerPage={skipPerPage ? skipPerPage : 0}
                    currentPage={currentPage}
                    itemPerPage={itemPerPage}
                    listPerPage={allowedPageSizes}
                    className={pagerFullScreen ? "paging-fixed" : ""}
                    onChangePage={(page) => {
                        if (this.dataGrid) {
                            this.dataGrid.instance.pageIndex(page);
                        }
                        if (onChangePage) onChangePage(page);
                    }}
                    onChangePerPage={(per) => {
                        if (this.dataGrid) {
                            this.dataGrid.instance.pageSize(per);
                            this.dataGrid.instance.pageIndex(0);
                        }
                        this.setState({itemPerPage: per});
                        if (onChangePerPage) onChangePerPage(per);
                    }}
                />}
            </div>
        );
    }
}

class TitleGrid extends Component {
    render() {
        let { label } = this.props;
        if (!label) return;

        return (
            <div className="grid-title">
                {label}
            </div>
        );
    }
}

TitleGrid.propTypes = {
    label: PropTypes.string
};

GridContainer.defaultProps = {
    pagerFullScreen: true,
    rowAlternationEnabled: true,
};

GridContainer.propTypes = {
    hoverStateEnabled: PropTypes.bool,
    selectedRowKey: PropTypes.any,
    title: PropTypes.string,
    type: PropTypes.string,
    field: PropTypes.string,
    itemPerPage: PropTypes.number,
    skipPerPage: PropTypes.number,
    totalItems: PropTypes.number,
    listPerPage: PropTypes.any,
    dataSource: PropTypes.any,
    keyExpr: PropTypes.string,
    showSendMail: PropTypes.bool,
    selection: PropTypes.object,
    buttonCRM: PropTypes.array,
    isPer: PropTypes.number,
    isPage: PropTypes.number,
    allowAdding: PropTypes.number,
    allowSearch: PropTypes.string,
    typeShort: PropTypes.bool,
    wordWrapEnabled: PropTypes.bool,
    editing: PropTypes.object,
    filterRow: PropTypes.object,
    allowColumnResizing: PropTypes.bool,
    columnResizingMode: PropTypes.string,
    columnAutoWidth: PropTypes.bool,
    reference: PropTypes.func,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    valueSearch: PropTypes.string,
    paging: PropTypes.any,
    pager: PropTypes.any,
    height: PropTypes.any,
    loadPanel: PropTypes.any,
    showBorders: PropTypes.bool,
    showColumnHeaders: PropTypes.bool,
    showColumnLines: PropTypes.bool,
    showRowLines: PropTypes.bool,
    scrolling: PropTypes.any,
    gridProps: PropTypes.any,
    loading: PropTypes.bool,
    rowAlternationEnabled: PropTypes.bool,
    pagerFullScreen: PropTypes.bool,
    elementAttr: PropTypes.any,
    allowCellSelection: PropTypes.bool,
    filterValue: PropTypes.any,
    filterPanel: PropTypes.object,
    filterBuilder: PropTypes.object,
    filterBuilderPopup: PropTypes.object,
    disabledCellSelection: PropTypes.bool,
    sorting: PropTypes.object,
    grouping: PropTypes.object,

    /**
     * @typePaging: String
     * "default": With default type of DataGrid Devextreme
     * "normal": With type of HR customize paging (local).
     * "remote": With type of HR customize paging (remote). Require props:
     *      - @totalItems: Total of all data.
     */
    typePaging: PropTypes.oneOf(['default', 'normal', 'remote']),

    /**
     * $isOrderAPI: Allow order with API with every column.
     */
    isOrderAPI: PropTypes.bool,

    onFilter: PropTypes.func,
    onAddNew: PropTypes.func,
    onSearch: PropTypes.func,
    onChangePage: PropTypes.func,
    onRowClick: PropTypes.func,
    onDbRowClick: PropTypes.func,
    onDbCellClick: PropTypes.func,
    onCellClick: PropTypes.func,
    onChangePerPage: PropTypes.func,
    customizeColumns: PropTypes.func,
    onSendMail: PropTypes.func,
    onRowInserted: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    onRowUpdated: PropTypes.func,
    onRowRemoved: PropTypes.func,
    onRowUpdating: PropTypes.func,
    onEditingStart: PropTypes.func,
    onInitNewRow: PropTypes.func,
    onRowRemoving: PropTypes.func,
    onEditorPrepared: PropTypes.func,
    onEditorPreparing: PropTypes.func,
    onContentReady: PropTypes.func,
    onRowDblClick: PropTypes.func,
    onRowPrepared: PropTypes.func,
    onFocusedCellChanging: PropTypes.func,
    onFocusedCellChanged: PropTypes.func,
    onRowInserting: PropTypes.func,
    onInitialized: PropTypes.func,
    onOptionChanged: PropTypes.func,
    onOrderChanged: PropTypes.func,
    onKeyDown: PropTypes.func,
    onCellHoverChanged: PropTypes.func,
    onCellSelectionChanged: PropTypes.func,
    onCellPrepared: PropTypes.func,
    loadMore: PropTypes.func,
    onRowValidating: PropTypes.func,
    onContextMenuPreparing: PropTypes.func,

    /**
     * get filter when changed..
     */
    onFilterChanged: PropTypes.func,
    onFilterGrid: PropTypes.func
};

export default GridContainer;
