/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 4/19/2021
 * @Example
 */

import React, {Component} from 'react';
import Config             from "../../config";

import {PopupV2 as PopupCore} from "diginet-core-ui/components";
import {withStyles}                 from "@material-ui/core";
import * as _                       from "lodash";

const styles    = {
    paper:          {
        alignSelf: 'start',
    },
    btnDetail:      {
        display:         "flex",
        alignItems:      "center",
        padding:         0,
        paddingRight:    5,
        fontSize:        11,
        backgroundColor: "#ddd !important"
    },
    collapseDetail: {
        marginTop:       5,
        padding:         "8px 10px",
        backgroundColor: "#EFF5FF",
        borderRadius:    5
        // borderTop: "1px solid #ddd",
    },
};
/**
 * Example:
 * Config.popup.show(message);
 * Config.popup.show(error);
 * Config.popup.show(type, message || objMessage);
 * Config.popup.show(options, message || objMessage);
 */

class Popup extends Component {
    constructor (props) {
        super(props);
        this.state = {
            show:     false,
            data:     '',
            detail:   '',
            options:  {},
        }
    }

    _getInit = (options, data, cbConfirm, cbCancel, cbClose, detail = null, lblBtnOk, lblBtnNo) => {
        if (!data && !cbConfirm && !cbCancel && !cbClose && !detail) {
            data = options;
            options = {type: typeof options === "object" ? "error" : "info"}
        } else if (typeof options === "string") {
            options = {type: options || "info"};
        }
        options = {yesText: lblBtnOk, noText: lblBtnNo, ...options};
        return {
            data:      data,
            options:   options,
            cbConfirm: cbConfirm,
            cbCancel:  cbCancel,
            cbClose:   cbClose,
            detail:    detail
        };
    };

    //options: not require
    show = (options, data, cbConfirm, cbCancel, cbClose, detail = null, lblBtnOk, lblBtnNo) => {
        let _options = this._getInit(options, data, cbConfirm, cbCancel, cbClose, detail, lblBtnOk, lblBtnNo);
        this.setState({
            show:      true,
            data:      _options.data,
            detail:    _options.detail,
            options:   _options.options,
            cbConfirm: _options?.cbConfirm ? () => {
                this.setState({show: false});
                _options?.cbConfirm && _options.cbConfirm();
            } : null,
            cbCancel: _options?.cbCancel ? () => {
                this.setState({show: false});
                _options?.cbCancel && _options.cbCancel();
            } : null,
            cbClose: _options?.cbClose ? () => {
                this.setState({show: false});
                _options?.cbClose && _options.cbClose();
            } : null,
        });
    };

    onClose = () => {
        this.setState({show: false});
        if (this.state.cbClose) this.state.cbClose();
    };

    onCancel = () => {
        this.setState({show: false});
        if (this.state.cbCancel) this.state.cbCancel();
    };

    onConfirm = () => {
        this.setState({show: false});
        if (this.state.cbConfirm) this.state.cbConfirm();
    };

    getType = () => {
        //Default: "info", "success", "warning", "danger", "yesno"
        const {type} = this.state?.options || {};
        switch (type?.toLowerCase()) {
            case "yes_no":
            case "yesno":
            case "yes-no":
                return "yesno";
            case "ERROR":
            case "error":
                return "danger";
            default:
                return type?.toLowerCase() || "info";
        }
    };

    getHtmlForText = (text) => {
        return text;
    };

    renderContent = (data) => {
        if (!data) return null;
        const {detail} = this.state;
        let _data                = {
            title: "",
            subtitle: "",
            description: ""
        };
        if (typeof data === "object") {
            if (data.originalError) {
                const {message, originalError}                 = data;
                const {code: codeError, message: messageError} = originalError || {};
                _data = {
                    title: Config.lang("Lỗi") + (codeError ? `: ${codeError}` : ""),
                    subtitle: this.getHtmlForText(message || Config.lang("Loi_chua_xac_dinh")),
                    description: this.getHtmlForText(messageError)
                };
            } else {
                const message = data?.message || data;
                _data = {
                    description: this.getHtmlForText(message || Config.lang("Loi_chua_xac_dinh")),
                };
            }
        } else if (detail) {
            _data = {
                subtitle: this.getHtmlForText(data || Config.lang("Loi_chua_xac_dinh")),
                description: this.getHtmlForText(detail)
            };
        } else {
            _data = {
                description: this.getHtmlForText(data || Config.lang("Loi_chua_xac_dinh")),
            };
        }

        return _data;
    };

    render () {
        const {show, data, options} = this.state;
        /* eslint-disable */
        const _data = this.renderContent(data);
        /* eslint-enable */
        const {title, subtitle, description} = _data || {};
        let type   = this.getType();
        let {icon, yesText, noText, confirmProps, cancelProps} = options || {};
        const excludeProps = ["icon", "type", "yesText", "noText", "confirmProps", "cancelProps"];

        return (
            <PopupCore
                css={{wordBreak:'break-word'}}
                icon={icon}
                pressESCToClose
                title={title}
                subtitle={subtitle}
                description={description || ''}
                type={type}
                open={show}
                yesText={yesText}
                noText={noText}
                confirmProps={confirmProps}
                cancelProps={cancelProps}
                onClose={this.onClose}
                onCancel={this.state.cbCancel ? this.onCancel : null}
                onConfirm={this.state.cbConfirm ? this.onConfirm : null}
                {..._.omit(options, excludeProps)}
            />
        );
    }
}

export default withStyles(styles)(Popup);

